<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="card__container">
      <div class="success__container">
        <img
          src="../../assets/imgs/financial/icon_success.png"
          alt="icon_success"
        />
        绑定成功！
      </div>
      <div class="card__name">{{ bankName }}({{ bankCode }})</div>
      <div class="btn__container">
        <div class="btn--finish">完成（{{ second }}s后自动跳转）</div>
      </div>
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "BindBankCardSuccess",
  components: {
    Breadcrumb
  },
  data() {
    return {
      bankName: this.$route.params.bankName,
      bankCode: this.$route.params.bankCode,
      second: 5
    };
  },
  watch: {
    second: {
      handler(newVal) {
        if (newVal === 0) {
          // this.$router.push({ name: "Recharge" });
          this.$router.push({
            name: "RechargeSecondStep",
            params: { price: this.$route.query.price }
          });
        }
      }
    }
  },
  created() {
    let timer = setInterval(() => {
      this.second--;
    }, 1000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
      timer = null;
    });
  }
};
</script>

<style scoped>
.success__container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: black;
  box-sizing: border-box;
  padding: 36px 0 20px 0;
}

.success__container img {
  width: 66px;
  height: 66px;
  margin-right: 37px;
}

.card__name {
  display: flex;
  justify-content: center;
  color: #c3c3c3;
  font-size: 16px;
}

.btn__container {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 40px 0;
}

.btn--finish {
  width: 200px;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(55, 105, 252, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
